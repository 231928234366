import { List, Datagrid, TextField, EditButton, 
    Edit, SimpleForm, TextInput, Create, SearchInput, 
    DateInput, DateField, Show, SimpleShowLayout, ShowButton, 
    FileField, BooleanInput, FunctionField } from "react-admin";
import { CheckCircle, Cancel } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

const softwareFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput label="Platform" source="platform" />,
    <TextInput label="Description" source="description" />,
];

const IsActiveIcon = ({ isActive }: { isActive: number }) => (
    <Tooltip title={isActive ? 'Active' : 'Inactive'}>
        {isActive 
            ? (<CheckCircle style={{ color: 'green' }} />)
            : (<Cancel style={{ color: 'red' }} />)
        }
    </Tooltip>
);

// detect using: ListGuesser
export const SoftwareList = (props: any) => (
    <List {...props} filters={softwareFilters} >
        <Datagrid>
            <TextField label="Id" source="id" />
            <TextField source="platform" />
            <TextField source="versionCode" />
            <TextField source="versionName" />
            <DateField source="releaseTime" />
            <TextField source="fileName" />
            <FunctionField
                label="Active?"
                render={(record: any) => <IsActiveIcon isActive={record.isActive} />}
            />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

// detect using: EditGuesser
// bug because BooleanInput expects true/false, 1/0 is used currently!
export const SoftwareEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput label="Id" disabled source="id" />
            <TextInput source="platform" />
            <TextInput source="versionCode" />
            <TextInput source="versionName" />
            <TextInput source="description" />
            <DateInput source="releaseTime" />
            <TextInput source="fileName" />
            <TextInput source="md5" />
            <BooleanInput source="isActive" />
        </SimpleForm>
    </Edit>
);

export const SoftwareCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="platform" />
            <TextInput source="versionCode" />
            <TextInput source="versionName" />
            <TextInput source="description" />
            <DateInput source="releaseTime" />
            <TextInput source="fileName" />
            <TextInput source="md5" />
            <BooleanInput source="isActive" />
        </SimpleForm>
    </Create>
);

export const SoftwareShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="platform" />
            <TextField source="versionCode" />
            <TextField source="versionName" />
            <TextField source="description" />
            <DateField source="releaseTime" />
            <FileField source="link.url" title="link.title" label="Install file" download="true" />
            <TextField source="md5" />
            <FunctionField
                label="Active?"
                render={(record: any) => <IsActiveIcon isActive={record.isActive} />}
            />
        </SimpleShowLayout>
    </Show>
    // use BooleanField instead
);
