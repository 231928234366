import { List, Datagrid, TextField, FunctionField, EditButton, Edit, SimpleForm, TextInput, Create, SearchInput, 
    DateInput, DateField, NumberInput, NumberField, Show, SimpleShowLayout, RichTextField, 
    ShowButton, ReferenceInput, SelectInput, DateTimeInput, FileInput, FileField, ReferenceField, 
    ReferenceArrayInput, AutocompleteArrayInput, ArrayField, ReferenceArrayField, ReferenceArrayFieldView, WithListContext, SingleFieldList, ChipField, FieldTitle, 
    AutocompleteInput, FieldToggle, BooleanInput, LinearProgress,
    FieldProps} from "react-admin";
import { Box, Typography } from '@mui/material';
import log from 'loglevel';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

log.setLevel('info'); // Set the desired log level (e.g., 'info', 'debug')


const eventFilters = [
    <SearchInput source="q" alwaysOn />,
];

const Progress = ({ progress }: { progress: number }) => {
    return (
        <Box display="flex" alignItems="center" width="100%">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                    {`${Math.round(progress )}%`}
                </Typography>
            </Box>
        </Box>
    );
};

const IsActiveIcon = ({ isActive }: { isActive: number }) => (
    <Tooltip title={isActive ? 'Active' : 'Inactive'}>
        {isActive 
            ? (<CheckCircle style={{ color: 'green' }} />)
            : (<Cancel style={{ color: 'red' }} />)
        }
    </Tooltip>
); 

// log.info(`[PercentageField] Record:`, record);

// detect using: ListGuesser
export const EventList = (props: any) => (
    <List {...props} filters={eventFilters} >
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="store_id" reference="stores">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="pin" />
            <TextField source="pc_operator_badge" />
            <FunctionField
                label="Progress"
                render={(record: any) => (
                    <Progress progress={record.progress || 0} />
                )}
            />
            <TextField source="start_time" />
            <TextField source="end_time" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

const optionRenderer = (choice: { id: any; firstname: any; lastname: any; }) => 
        `${choice.id} - ${choice.firstname} ${choice.lastname}`;

// detect using: EditGuesser
export const EventEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput source="store_id" reference="stores">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput source="auditors" reference="auditors">
                <AutocompleteArrayInput optionText={optionRenderer} />
            </ReferenceArrayInput>
            {/* <NumberInput source="anagraphic_id" /> */}
            <FileInput source="attachments_info" label="Anagraphic attachment" multiple>
                <FileField source="src" title="title" />
            </FileInput>
            <TextInput source="pin" />
            <TextInput source="auth" />
            {/* <NumberInput source="valuePaid" />
            <NumberInput source="valueTaxes" /> */}
            <ReferenceArrayInput source="pc_operator_badge" reference="auditors">
                <AutocompleteInput optionText={optionRenderer} />
            </ReferenceArrayInput>
            <DateTimeInput source="start_time" />
            <DateTimeInput source="end_time" />
            <BooleanInput source="expire_date_enabled" label="Expire date enabled?" />
            <TextInput source="description" />
        </SimpleForm>
    </Edit>
);

export const EventCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <ReferenceInput source="store_id" reference="stores">
                <SelectInput optionText="name" />
            </ReferenceInput>
            {/* <ReferenceArrayInput source="store_id" reference="stores">
                <AutocompleteArrayInput optionText="name" debounce={500} />
            </ReferenceArrayInput> */}
            <ReferenceArrayInput source="auditors" reference="auditors">
                <AutocompleteArrayInput 
                    // fullWidth
                    //optionText={record => record.firstname}
                    optionText={optionRenderer} />
            </ReferenceArrayInput>
            {/* <NumberInput source="anagraphic_id" /> */}
            <FileInput source="attachments_info" label="Anagraphic attachment" multiple>
                <FileField source="src" title="title" />
            </FileInput>
            <TextInput source="pin" />
            <TextInput source="auth" />
            {/* <NumberInput source="valuePaid" />
            <NumberInput source="valueTaxes" /> */}
            <ReferenceArrayInput source="pc_operator_badge" reference="auditors">
                <AutocompleteInput optionText={optionRenderer} />
            </ReferenceArrayInput>
            <DateTimeInput source="start_time" />
            <DateTimeInput source="end_time" />
            <BooleanInput source="expire_date_enabled" label="Expire date enabled?" />
            <TextInput source="description" />
        </SimpleForm>
    </Create>
);

export const EventShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <ReferenceField source="store_id" reference="stores">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceArrayField source="auditors" reference="auditors">
                {/* <SingleFieldList>
                    <ChipField source="id" />
                    <ChipField source="firstname" />
                </SingleFieldList> */}
            </ReferenceArrayField>
            {/* <TextField source="anagraphic_id" /> */}
            <FileField source="attachments_info[0].src" 
                    title="attachments_info[0].title" label="Anagraphic" download="true" />
            <FileField source="link.url" label="Closeout archive" title="link.title" download="true" />
            <TextField source="pin" />
            <TextField source="auth" />
            <FunctionField
                source="progress"
                render={(record: any) => `${record.progress || 0}%`}
                label="Progress"
            />
            {/* <TextField source="valuePaid" />
            <TextField source="valueTaxes" /> */}
            <TextField source="pc_operator_badge" />
            <DateField source="start_time" />
            <DateField source="end_time" />
            <FunctionField
                label="Expire date enabled?"
                render={(record: any) => <IsActiveIcon isActive={record.expire_date_enabled} />}
            />
            <RichTextField source="description" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
    // use BooleanField instead
);
