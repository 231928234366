// in src/users.js
import * as React from "react";
import { List, Datagrid, TextField, EmailField, TextInput, 
    DateInput, Edit, NumberInput, SimpleForm, DateTimeInput, EditButton, Create, BooleanInput, PasswordInput, FilterButton, TopToolbar, CreateButton, ExportButton, FilterList, SearchInput, 
    FunctionField} from 'react-admin';
import { Box, Typography } from '@mui/material';
import log from 'loglevel';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

const userFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput label="Username" source="username" />,
    <TextInput label="Is Active?" source="isActive" />,
];

const IsActiveIcon = ({ isActive }: { isActive: number }) => (
    <Tooltip title={isActive ? 'Active' : 'Inactive'}>
        {isActive 
            ? (<CheckCircle style={{ color: 'green' }} />)
            : (<Cancel style={{ color: 'red' }} />)
        }
    </Tooltip>
); 

// detect using: ListGuesser
export const UserList = (props: any) => (
    <List {...props} filters={userFilters}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="username" />
            <TextField source="createdAt" />
            <TextField source="updatedAt" />
            {/* todo: fix this field: BooleanInput */}
            <FunctionField
                label="Active?"
                render={(record: any) => <IsActiveIcon isActive={record.isActive} />}
            />
            <TextField source="role" />
            <EditButton />
        </Datagrid>
    </List>
);

// detect using: EditGuesser
export const UserEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="username" />
            <TextInput source="firstname" />
            <TextInput source="lastname" />
            {/* todo: fix this field: BooleanInput */}
            <BooleanInput source="isActive" />
            <NumberInput source="role" />
        </SimpleForm>
    </Edit>
);

export const UserCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="email" />
            <PasswordInput source="password" />
            <TextInput source="firstname" />
            <TextInput source="lastname" />
            {/* todo: fix this field: BooleanInput */}
            <BooleanInput source="isActive" />
            <NumberInput source="role" />
        </SimpleForm>
    </Create>
);
